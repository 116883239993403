export const ProductPostSkeleton = () => {
  return (
    <>
      <div className="flex flex-col gap-3">
        <div className="flex items-center gap-2 px-2">
          <div className="flex flex-1 items-center gap-3">
            <div className="skeleton-loader size-8 rounded-full" />
            <div>
              <div className="skeleton-loader h-3 w-20 rounded-sm" />
              {/* <div className="w-12 h-2 rounded-sm mt-1" /> */}
            </div>
          </div>
          <div className="skeleton-loader h-4 w-12 rounded pr-2" />
        </div>
        <div className="main-skeleton-loader aspect-square w-full" />
        <div className="px-2 py-1">
          <div className="skeleton-loader h-4 w-1/2 rounded" />
          <div className="mt-2 flex items-center gap-4">
            <div className="skeleton-loader h-2.5 w-28 rounded" />
            <div className="h-2.5 w-28 rounded" />
          </div>

          <div className="" />
        </div>
        <div className="flex justify-between px-3">
          <div className="h-8 w-28 rounded-[10px]" />
          <div className="h-8 w-28 rounded-[10px]" />
        </div>
      </div>
    </>
  );
};

export const SliderSkeleton = () => {
  return (
    <>
      <div className="skeleton-loader h-52 w-full" />
    </>
  );
};
